import React from "react";
import { Link } from "gatsby";

const PostItem = ({ path, title, date }) => (
  <Link to={path} style={{ width: "100%" }}>
    <div className="note warning">
      <h3 style={{ margin: 0 }}>{title}</h3>
      <p style={{ marginBottom: 0, marginTop: 10, fontSize: 14 }}>
        <i>{date}</i>
      </p>
    </div>
  </Link>
);

export default PostItem;

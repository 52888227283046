import React from "react";
import PostItem from "./PostItem";

class PostListing extends React.Component {
  getPostList() {
    const postList = [];
    // eslint-disable-next-line react/destructuring-assignment
    this.props.postEdges.forEach((postEdge) => {
      postList.push({
        path: postEdge.node.fields.slug,
        tags: postEdge.node.frontmatter.tags,
        cover: postEdge.node.frontmatter.cover,
        title: postEdge.node.frontmatter.title,
        date: postEdge.node.frontmatter.date,
        excerpt: postEdge.node.excerpt,
        timeToRead: postEdge.node.timeToRead,
      });
    });
    return postList;
  }

  render() {
    const postList = this.getPostList();
    return (
      <>
        {
          /* Your post list here. */
          postList.map((post) => (
            <PostItem
              path={post.path}
              title={post.title}
              key={post.title}
              date={post.date}
              excerpt={post.excerpt}
              timeToRead={post.timeToRead}
            />
          ))
        }
      </>
    );
  }
}

export default PostListing;
